.topBarContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 80px;
    background-color: black;
}

#topBarContentContainer {
    width: 80%;
    max-width: 1600px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#topBarContentContainer section {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 55%;
}

.topBarText{
    color: #00dd4d;
    font-size: calc(5px + .7vw);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    transition-duration: .4s;
}

.topBarText:hover {
    color: var(--detail);
}

.topBarTextItem{
    color: var(--secondary-text);
    font-size: calc(4px + .6vw);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    padding-left: calc(1px + .4vw);
}

.topBarTextContainer{
    display: flex;

    height: 5vh;
    justify-content: center;
    align-items: center;
}

.topBarInputContainer {
    display: flex;
    align-items: center;
    width: 20%;
    height: 50%;
    background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 5px;
}

.topBarInputContainer:focus-within  {
    outline: 2px solid var(--detail);
}

.topBarInput{
    display: flex;
    width: 100%;
    height: 100%;
    background-color: white;
    outline: none;
    border: none;
}

