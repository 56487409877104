.params-config-container {
    width: 85%;
    /* border: 1px solid red; */
    align-self: center;
    padding-left: 10px;
    padding-bottom: 5px;
}

.SoftwareModal > div:first-child {
    display: flex;
    width: 85%;
    justify-content: space-between;
    align-self: center;
    padding: 0 10px;
}

.params-config-item {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 65%;
}

.params-config-item > span {
    font-size: .8rem;
    color: white;
}

.params-config-item > *:last-child {
    width: 100%;
    margin-top: 10px;
}

.mainSoftwareContainer{
    display: flex;
    flex-direction: row;
    width: 80%;
    height: 65vh;
    background-color: black;
    max-width: 1200px;
}

.softwareContainerLeft{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 100%;
    background-color: white;
    align-items: flex-start;
    padding: 5rem;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.SoftwareModal{
    display: flex;
    flex-direction: column;
    width: 50vw;
    height: 80vh;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
    z-index: 99999999;
    background-color: #383636;
    overflow: auto;
    border-radius: 5px;
}

.softwareContainerRight{
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    background-color: #383636;

    padding: 5rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.configureButton {
    display: flex;
    flex-direction: row !important;
    justify-content: space-around !important;
    align-items: center;
    align-self: flex-start;

    max-width: 180px;
    height: 3.2rem !important;
    color: black;
    background-color: white;
    border-radius: 5px;
    font-size: .8rem;
    cursor: pointer;
    
    transition: scale .4s;
}

.configureButton:hover {
    scale: 1.05;
}

.itemContainerUpdate {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 35%;
    background-color: white;
    align-self: center;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-sizing: border-box;

    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #D9D9D9; /* thumb and track color */
}

.itemContainerUpdate span {
    font-size: calc(5px + .6rem);
}

.versionInfoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: flex-start;
    border-radius: 5px;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
}

.versionInfoContainer span {
    font-size: calc(6px + .6rem);
    font-weight: 500;
    color: white;
}

.softwareContainerRight > span:first-of-type {
    font-size: calc(10px + 2rem);
    font-weight: bold;
    color: white;
} 

.faSyncContainer{
    display: flex;
    flex-direction: column;
    width: 4rem;
    height: 2.5rem;
    background-color: #00FF58;
    align-self: center;
    border-radius: 5px;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    padding: .5rem;
    box-sizing: border-box;
    transition: scale .4s;
}

.faSyncContainer:hover{
    scale: 1.05;
}

.aboutText{
    display: flex;
    margin-left: 8.5px;
    font-size: calc(5px + .7vw);
}

.rotate-animation {
    transition: transform 2s;
    transform: rotate(180deg);
}