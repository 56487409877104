.content-container > div:first-of-type {
    margin-top: 40px;
    width: 100%;
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content-container p {
    font-size: 1rem;
}

.content-container > div:first-of-type p {
    /* border: 1px solid red; */
    color: var(--text);
    font-size: 1.2rem !important;
    font-weight: 500;
    padding-left: 35px;
}

#our-values > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 100px;
    /* border: 1px solid blue; */
}

.our-values-content {
    display: flex;
    flex-direction: column;
    width: 35%;
    text-align: start;
}

.our-values-content p:nth-of-type(2) {
    line-height: 2;
    color: var(--text);
}

.our-values-content p:first-of-type {
    color: var(--text);
    font-size: 1.5rem !important;
    font-weight: 700;
    /* border: 1px solid red; */
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1 !important;
}

#our-history > div {
    text-align: start;
}

#our-history li {
    margin-bottom: 10px;
    font-size: 1rem;
}

#our-history b {
    color: var(--detail);
}

.our-values-img {
    width: 60%;
    height: 500px;
    border-radius: 5px;
}

.our-values-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}

.content-section > p:nth-of-type(2) {
    text-align: start;
    line-height: 2;
}

.paragraph {
    line-height: 2;
}

.catchphrase {
    color: var(--detail);
    font-size: calc(2.5rem + 2vw) !important;
    font-weight: 700;
}

@media (max-width: 800px) {
    #our-values > div {
        flex-direction: column;
    }

    #our-values > div:nth-child(2n) {
        flex-direction: column-reverse;
    }

    .our-values-img, .our-values-content {
        width: 100%;
    }

    .our-values-content {
        margin-top: 40px;
    }
}