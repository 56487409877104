footer {
    background-color: black;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary-text);
}

#footer-content {
    width: 80%;
    max-width: 1200px;
    /* border: 1px solid red; */
}

#footer-info {
    width: 80%;
    min-width: 360px;
    /* border: 1px solid red; */

    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

#footer-info > div:last-child {
    justify-self: end;
}

#footer-company-field {
    text-align: start;
    padding-right: 0;
    margin-right: 0;
    width: fit-content;
    display: flex;
    flex-direction: column;
}

#footer-company-field > *:not(:first-child):not(:last-child) {
    margin-bottom: 10px;
}

#footer-company-field > *:not(:first-child) {
    font-size: 1rem;
    transition-duration: .4s;
    width: fit-content;
}

#footer-company-field > *:not(:first-child):not(span):hover {
    cursor: pointer;
    color: var(--detail);
}

#footer-company-field > span {
    cursor: default;
    color: grey;
}

#footer-info div > p:first-child {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--detail);
}


#footer-info > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#footer-info div:nth-child(2) a {
    padding: 0 !important;
    cursor: pointer;
}

#footer-info div:nth-child(2) a img {
    width: 100%;
    max-width: 165px;
}

#footer-info div:nth-child(2) > a:first-of-type {
    margin-bottom: 10px;
}

#footer-company-info {
    font-size: 1rem;
    border-top: 2px solid var(--terciary-text);
    margin-top: 20px;
    padding-top: 20px;
}

#footer-company-info > div:first-child {
    text-align: start;
    width: 50%;
}

#footer-info div:last-of-type div {
    display: flex;
    flex-direction: row;
    width: 90px;
    justify-content: space-between;
}

.footer-icon {
    transition-duration: .4s;
}

.footer-icon:hover {
    cursor: pointer;
    color: var(--detail);
}

@media (max-width: 800px) {
    #footer-info {
        width: 70%;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
    }

    #footer-info > div:nth-child(2) {
        justify-self: end;
    }

    #footer-info > div:nth-child(3) {
        justify-self: start;
    }

    #footer-info > div:nth-child(4) {
        grid-column: 1 / span 2;
    }
}


@media (max-width: 460px) {
    #footer-info {
        width: 100%;
        grid-template-columns: repeat(2, .4fr);
    }
}