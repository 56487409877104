@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

*:not(p) {
	margin: 0;
	padding: 0;
	text-decoration: none;
	box-sizing: border-box;
	/* border: 1px solid red; */
}

html, body {
	width: 100%;
	overflow-x: hidden;
}

:root {
	--detail: #00ff58;
	--secondary-detail: #414141;
	--bg: white;
	--secondary-bg: black;
	--terciary-bg: #535353;
	--text: black;
	--secondary-text: white;
	--terciary-text: #b3b3b1;
}

body {
	font-size: 62.5%; /* Seta o font-size padrão para 10px */
	font-family: 'Source Sans 3', sans-serif;
	font-weight: 400;
	font-optical-sizing: auto;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}