#breathalyzer > p:first-of-type {
    text-align: start;
}

#breathalyzer > button {
    width: 180px;
}

#catchphoto-container {
    background-color: grey;
    width: 100%;
    height: 900px;
}

#catchphoto-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    /* position: absolute; */
}

#product-chars-container {
    margin-top: 40px;
}

#product-chars-container div > label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid lightgrey;
    cursor: pointer;
}

#product-chars-container div > label > p:first-of-type {
    font-size: 16pt;
    font-weight: 500;
}

#product-chars-container div > p {
    text-align: start;
}

#product-chars-container div div {
    max-height: 0;
    overflow: hidden;
    transition: all .4s ease-in-out;
}

#product-chars-container div input:checked ~ div {
    max-height: fit-content;
    overflow: visible;
}

#breathalyzer > div:last-of-type {
    margin-top: 40px;
}

#breathalyzer > div > p:first-of-type {
    text-align: start;
    font-weight: 500;
    font-size: 14pt;
}

#breath-specs {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#breath-specs p {
    width: 100%;
    padding: 0;
    margin-top: 40px;
}
