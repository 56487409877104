#slider-container {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    position: relative;
}

/* #slider-container::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100%;
    background: linear-gradient(to left, transparent, var(--bg));
    content: "";
    z-index: 2;
} */

@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.imgs-slide {
    display: flex;
    flex-direction: row;
    animation: 10s slide infinite linear;
}

#slider-container:hover .imgs-slide {
    animation-play-state: paused;
}
