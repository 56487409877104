.table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50vh;
    border-radius: 10px;
    background-color: #383838;
    
    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #383838; /* thumb and track color */
}

.table::-webkit-scrollbar {
    width: 2px;
}
  
.table::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}
  
.table-row {
    display: flex;
    border-radius: 10px;
    cursor: pointer;
}

.table-row:hover {
    background-color: #aaacae;
}
  
.table-cell {
    flex: 1; 

    border-bottom: 1px solid #727171;
    border-right: 1px solid #727171;
    
    padding: 8px; 
    text-align: center; 
}
  
.table-row .table-cell:last-child {
    border-right: none;
}
  