.AppInfo{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100dvh;
    justify-content: space-evenly;
}

.AppInfo .TitleContainer{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    color: white;
    font-size: 1.8rem;
}

.AppInfo .ItemsContainer{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.AppInfo .VideoContainer{
    display: flex;
    position: relative;
    width: 48%;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 1rem;
}

.AppInfo .VideoButton{
    display: flex;
    position: absolute;
    top: 40%;
    left: 45%;
    width: 3rem;
    height: 3rem;
    z-index: 1;
}

.AppInfo .InfoContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 49%;
    border-radius: 1rem;
    box-sizing: border-box;
    background-color: white;
    padding: 1.2rem;
}