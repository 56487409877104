#container {
    width: 100vw;
    height: 100vh;
    /* border: 1px solid red; */
}

.content-container {
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    /* border: 1px solid red; */
}

.content-container p:not(.subsection-title):not(.subsection-subtitle):not(.catchphrase) {
    line-height: 2;
}

.content-section {
    margin-bottom: 120px;
    width: 100%;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    margin-bottom: 200px;
    /* border: 1px solid red; */
}

.section .subsection:last-child {
    margin-bottom: 0;
}

.subsection {
    margin-bottom: 150px;
    width: 100%;
}

.section-title {
    font-size: 1.6rem !important;
    color: var(--detail);
    font-weight: 700;
    text-align: start;
    width: 100%;
    line-height: normal !important;
}

.subsection-title {
    font-size: calc(2.5rem + 2vw) !important;
    color: var(--text);
    font-weight: 700;
    margin-bottom: 20px !important;
}

.subsection-subtitle {
    color: var(--text);
    font-size: 1.1rem !important;
    line-height: 2;
}

.disabled-button {
    cursor: not-allowed;
    opacity: .7;
}

.primary-button {
    all: unset;
    padding: 10px;
    color: var(--secondary-text);
    background-color: #00dd4d;
    border-radius: 5px;
    cursor: pointer;
    height: 30px;
    width: 120px;
    transition: .4s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1rem;
    transition: background-color .4s;
}

.primary-button:hover {
    background-color: var(--detail);
}

.subsection-header {
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
}

.section > div.subsection-header:first-child {
    margin-top: 0;
}

.subsection-header {
    text-align: start;
    width: 100%;
    margin-bottom: 40px;
}