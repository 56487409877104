.mainInfoContainer{
    display: flex;
    flex-direction: row;
    width: 75%;
    height: 70vh;
    justify-content: space-between;
}

.leftContainer{
    display: flex;
    flex-direction: column;
    width: 49%;
    height: 100%;
    justify-content: space-between;
}

.rightContainer{
    display: flex;
    flex-direction: column;
    width: 49%;
    height: 100%;
    align-items: flex-start;
    background-color: #383636;
    border-radius: 1rem;
    padding: 3rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;

    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #383636; /* thumb and track color */
}

.rightContainer::-webkit-scrollbar {
    width: 2px;
}
  
.rightContainer::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.leftItemContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 20%;
    padding: 1rem;
    padding-left: 3rem;
    padding-right: 1rem;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
    background-color: #383636;
    border-radius: 1rem;
    cursor: pointer;
    user-select: none;
}

.leftItemContainer:hover{
    background-color: gray;
}

.infoText{
    display: flex;
    color: white;
    align-items: center;
    width: 100%;
    height: 100%;
}