a {
    all: unset;
}

#header-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    height: 60px;
    background-color: var(--secondary-bg);
    font-size: 1rem;
}

#header-content, #info-container, 
#left-panel, #right-panel,
#left-panel > div, #panel-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#panel-container {
    width: 100%;
}

#header-content {
    width: 80%;
    max-width: 1200px;
    background-color: var(--secondary-bg);
}

#logo {
    padding-right: 20px;
    cursor: pointer;
}

#info-container {
    width: 50%;
    min-width: 380px;
    /* height: 100%; */
    /* max-width: 500px; */
    /* border: 1px solid blue; */
}

#left-panel {
    width: 40%;
    min-width: 240px;
    max-width: 350px;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
    margin-right: 10px;
    /* height: 100%; */
}

#left-panel > a {
    color: var(--secondary-text);
    transition-duration: .4s;
}

#left-panel > a:hover {
    color: var(--detail);
    cursor: pointer;
}

#left-panel > div {
    position: relative;
}

#left-panel > div > label {
    /* border: 1px solid red; */
    width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#left-panel > div > label * {
    transition: color .4s;
    cursor: pointer;
    color: var(--secondary-text);
}

#left-panel > div > label:hover * {
    color: var(--detail);
}

#company-dropdown {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px 0 5px 10px;
    background-color: var(--terciary-bg);
    width: 140px;
    height: 80px;
    border-radius: 5px;
    color: var(--secondary-text);
    border: 1px solid var(--terciary-bg);
}

#company-dropdown a {
    all: unset;
    transition: color .4s;
}

#company-dropdown a:hover {
    cursor: pointer;
    color: var(--detail);
}

#toggle-company-dropdown:checked ~ #info-container #company-dropdown {
    display: flex;
    position: absolute;
    top: 30px;
}

#right-panel {
    /* border: 1px solid red; */
    width: 300px;
}

#right-panel button, #open-side-panel {
    all: unset;
    border: 1px solid var(--secondary-detail);
    border-radius: 5px;
    color: var(--secondary-text);
    transition-duration: .4s;
}

#right-panel button:hover:not(:disabled), #open-side-panel:hover {
    cursor: pointer;
    background-color: var(--detail);
    border: 1px solid var(--detail);
}

#right-panel button {
    padding: 10px 20px 10px;
}

#open-side-panel {
    padding: 5px 10px 5px;
    display: none;
}

#close-side-panel {
    display: none;
}

#close-side-panel label:hover {
    all: unset;
    cursor: pointer;
}

#info-container button:disabled {
    cursor: default;
    color: grey;
    transition: none;
}

@media (max-width: 1300px) {
    #info-container {
        position: fixed;
        top: 0px;
        right: -100%;
        height: 100%;
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        transition: all .4s ease-in-out;
        background-color: black;
    }

    #close-side-panel {
        display: flex;
        /* border: 1px solid red; */
        width: 100%;
        padding-left: 20px;
        padding-top: 5px;
    }

    #open-side-panel {
        display: block;
    }

    #toggle-side-panel:checked ~ #info-container {
        right: 0px;
    }

    #left-panel, #right-panel {
        width: 100%;
        min-width: auto;
        max-width: auto;
    }

    #left-panel > div > label,
    #left-panel > a {
        margin-bottom: 10px;
    }

    #right-panel {
        height: 50px;
        margin-top: 20px;
    }

    #right-panel button {
        border: 0;
        padding: 0;
        color: var(--detail);
    }

    #right-panel button:hover {
        background: none !important;
        color: var(--secondary-text);
        border: none !important;
    }

    #info-container, #panel-container,
    #left-panel, #right-panel {
        flex-direction: column;
        align-items: center;
    }
}