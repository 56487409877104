#carousel-wrapper {
    position: relative;
}

#carousel-slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    aspect-ratio: 16/9;
    box-shadow: 0 1.5rem 3rem -.75rem hsla(0, 0%, 0%, .25);
    border-radius: 5px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}


#carousel-slider::-webkit-scrollbar {
	display: none;
}

#carousel-slider * {
    flex: 1 0 100%;
    scroll-snap-align: start;
    object-fit: cover;
}

#carousel-nav {
    display: flex;
    column-gap: 1rem;
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    /* border: 1px solid red; */
}

#carousel-nav a {
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background-color: #fff;
    opacity: .75;
    transition: opacity ease 250ms;
}

#carousel-nav a:hover {
    opacity: 1;
    cursor: pointer;
}