#policy-container {
    display: flex;
    flex-direction: column;
    text-align: justify;
    height: 100vh;
    align-items: center !important;
    justify-content: center !important;
    /* border: 1px solid red; */
    background-color: var(--secondary-bg);
}

#policy-container > p:first-of-type {
    font-size: 3rem;
    font-weight: 700;
    color: var(--detail);
}

#policy-container > p:nth-of-type(2) {
    color: var(--secondary-text);
}

div:has(#policy-container) {
    background-color: var(--secondary-bg) !important;
}