body {
    background-color: var(--bg);
    margin: 0;
    padding: 0;
}

#container {
    height: 100vh;
    width: 100%;
}

#content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#content p {
    margin: 0;
}

#first-content {
    background-image: url('../../../assets/img/landing_no_mark.jpg');
    /* background-size: cover;
    background-position: center;*/
    background-repeat: no-repeat;
    width: 100% !important;
    height: 1000px;
    margin-bottom: 40px;
    text-align: start;
    padding-top: 100px;
    padding-left: 60px;
}

#first-content p {
    color: var(--secondary-text) !important;
}

#first-content p {
    width: 40%;
}

#first-content > p:nth-child(2) {
    min-width: 250px;
}

#first-content button {
    margin-top: 20px;
}

#content > div#first-content.section {
    margin-bottom: 40px;
}

#video {
    height: 600px;
    width: 100%;
    background-color: black;
    margin-bottom: 40px;
}

#product-section > .subsection-header:first-child {
    margin-top: 40px;
}

.app-carousel-item img {
    height: 600px;
    margin: 0 14px;
    border-radius: 5px;
    pointer-events: none;
}

.app-carousel-item > div {
    border-radius: 5px;
}

.partners-carousel-item > div {
    background-color: var(--secondary-bg);
    padding: 14px;
    margin: 0 15px;
    border-radius: 5px;
    min-width: 200px;
    max-width: 200px;
    width: 200px;
    height: 150px;
    border: 1px solid var(--detail);
    display: flex;
    align-items: center;
    justify-content: center;
}

.partners-carousel-item img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.image-list-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.image-list-container .img-image-list {
    background-color: black;
    border-radius: 5px;
    width: 55%;
}

.image-list-container > .advantage-list {
    /* border: 1px solid red; */
    width: 40%;
}

.img-image-list img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
}

#advantage-table {
    width: 100%;
    height: auto;
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 150px);
    gap: 8%;
    /* border: 1px solid red; */
}

#advantage-table > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#advantage-table div {
    background-color: var(--secondary-bg);
    border-radius: 5px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--secondary-text);
    transition-duration: .4s;
    text-align: center;
}

#advantage-table div:hover {
    background-color: hsl(0, 0%, 12%);
}

#advantage-table div p {
    font-size: 1rem;
    font-weight: 500;
    width: 60%;
    margin-top: 10px;
}

#visite-us-section div:nth-child(2) {
    width: 100%;
    /* border: 1px solid red; */
}

#visite-us-section div:nth-child(2) p {
    text-align: start;
}


.advantage-list {
    text-align: start;
}

.advantage-list div:not(:last-child) {
    margin-bottom: 20px;
}

.advantage-list div > p:first-child,
#visite-us-section div:nth-child(2) p:first-of-type {
    color: var(--text);
    font-size: 18pt;
    font-weight: 700;
    margin-bottom: 10px !important;
}

.advantage-list div > p:nth-child(2) {
    color: var(--text);
    font-size: 12pt;
    line-height: 2;
}

@media (max-width: 1200px) {
    #advantage-table {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 150px);
        column-gap: 10%;
        margin-bottom: 80px;
    }
}

@media (max-width: 900px) {
    #advantage-table {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 150px);
        column-gap: 15%;
        margin-bottom: 120px;
    }
}

@media (max-width: 800px) {
    .image-list-container {
        flex-direction: column;
    }

    .image-list-container .img-image-list {
        height: 400px;
        width: 100%;
        margin-bottom: 40px;
    }

    .image-list-container .advantage-list {
        width: 100%;
    }

    div.image-list-container:nth-last-child(2) {
        flex-direction: column-reverse;
        /* border: 1px solid red; */
    }
}

@media (max-width: 500px) {
    #first-content .subsection-title {
        font-size: 3rem !important;
    }

    #advantage-table div p {
        font-size: .8rem;
    }
}