.ControlContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;       
    padding: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
    box-sizing: border-box;
    border-radius: 20px;
}

.ControlContainer > div:first-child div span:nth-of-type(2) {
    font-size: calc(6px + .6rem);
    /* border: 1px solid red; */
}

.controlItem > .table > .table-row:first-child .table-cell {
    background-color: #242424;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + .6vw);
    font-weight: bold;
}

.controlItem > .table > .table-row:first-child:hover {
    scale: none;
    background: none;
}

.controlItem > .table > .table-row:not(:first-child) .table-cell {
    font-size: calc(5px + .6rem);
}

.plateOption{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    cursor: pointer;
}

.plateOption:hover{
    scale: 1.01;
}

.plateText{
    color: white;
}

.plateLine{
    width: 100%;
    height: 3px;
    opacity: 0.6;
    background-color: white;
}

.controlItem {
    display: flex;
    flex-direction: column;
    width: 23%;
    color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 1);
    padding: 1rem;
    box-sizing: border-box;
    align-items: center;
    overflow: hidden;
    border-radius: 20px;
}

.controlItem > *:first-child {
    font-size: calc(6px + .6rem);
    font-weight: 700;
}

.graphContainer{
    display: flex;
    flex-direction: column; 
    justify-self: space-between;
    width: 100%;
    height: 80%;
}

.carIconContainer{
    display: flex;
    align-content: center;
    justify-content: center;
    width: 45%;
    height: 80%;
    min-height: 100px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
}

.carIcon {
    height: 3rem;
    min-height: 10px;
    color: black;
    align-self: center;
}

.buttonTravel{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: beige;
    height: 3rem;
    padding-left: 1rem;
    user-select: none;
    font-size: calc(5px + .5rem);
}

.unlockIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 80%;
    border-radius: 8px; 
    cursor: pointer;
    box-sizing: border-box;
    background-color: #00FF58; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    transition: all 0.3s ease; 
}

.unlockIconContainer:hover {
    background-color: #00c944;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
}

.unlockIcon {
    height: 1.7rem; 
    color: black;
    transition: color 0.3s ease; 
}

.unlockIconContainer:hover .unlockIcon {
    color: #e0ffe6; 
}

.unlockModal {
    background-color: #121212;
    height: 30%;
    width: 30%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0; 
    border-radius: 10px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
    z-index: 1000;
    animation: fadeIn 0.4s ease-in-out forwards; 
}

/* Definição da animação */
@keyframes fadeIn {
    0% {
        transform: translate(-50%, -50%) scale(0.8); /* Menor e deslocado */
        opacity: 0; /* Invisível */
    }
    100% {
        transform: translate(-50%, -50%) scale(1); /* Escala normal */
        opacity: 1; /* Totalmente visível */
    }
}

.buttonUnlock{
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    border-radius: 10px;
    background-color: #00FF58;
    cursor: pointer;
    transition-duration: .4s;
    border: none;
}

.buttonUnlock:hover{
    scale: 1.05;
}

.scrollable-div {
    overflow-y: auto; /* Ativa a barra de rolagem vertical */
    padding-right: 10px; /* Evita sobreposição com a barra */
    width: 90%;
}

.scrollable-div::-webkit-scrollbar {
    width: 8px; /* Largura da barra */
}

.scrollable-div::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1); /* Fundo da barra */
    border-radius: 10px; /* Bordas arredondadas */
}

.scrollable-div::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3); /* Cor do "polegar" */
    border-radius: 10px; /* Bordas arredondadas */
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5); /* Cor ao passar o mouse */
}

span {
    color: white;
}
