.InstalationContainer{
    display: flex;
    flex-direction: column;
    width: 75%;
    flex: .85;
}

.InstalationContainer .reports_new{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 65vh;
}

.InstalationContainer .itemContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    align-items: center;
}

.InstalationContainer .iconItem{
    font-size: 2rem;
    color: white;
}

.InstalationContainer .downloadItem{
    display: flex;
    font-size: 1.4rem;
    color: gray;
    cursor: pointer;
}

.InstalationContainer .downloadItemDisabled{
    display: flex;
    font-size: 1.4rem;
    color: gray;
    cursor: not-allowed;
    opacity: 0.5;
}


.InstalationContainer .line{
    display: flex;
    width: 100%;
    height: 2px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: white;
    opacity: .7;
}

div.ScrollViewContainer.reports_new > div:first-of-type {
    background-color: #242424;
    padding: 15px 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.InstalationContainer .columnItem{
    display: flex;
    align-items: center;
    width: 20%;
    justify-content: center;
    color: white;
    font-size: 1rem;
    font-weight: 500;
}

.InstalationContainer .itemText{
    color: white;
    font-size: 1rem;
}

