.MainContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #121212;
    overflow-x: hidden;
}

.CentralBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    overflow-x: hidden;
}

.CentralBlock .table {
    font-size: calc(6px + .6vw);
}

.HomeInfoContainer #main-table-header .table-cell {
    background-color: #272727;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + .6vw);
    font-weight: bold;
}

.HomeInfoContainer #main-table-header:hover {
    scale: none;
    background: none;
    cursor: default;
}

.DataFillContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    height: 120px;
}
  
.DataFillContainer[data-is-super-admin="true"] {
    width: 80%;
}

.DataFillContainer[data-is-super-admin="false"] {
    width: 40%;
}

.DataFillContainer > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 100px;
    /* border: 1px solid blue; */
}

.DataFillContainer[data-is-super-admin="true"] > div {
    width: 20%;
}

.DataFillContainer[data-is-super-admin="false"] > div {
    width: 40%;
}

.DateSelectorContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 20%;
    height: 5vh;

    padding: 1rem;
    box-sizing: border-box;
    
    border: 1px solid white;
    border-radius: 7px;
}

.DropDownHomeContainer{
    width: 15%;
    height: 5vh;
    
    border: 1px solid white;
    border-radius: 7px;
}

.buttonInfoContainer{
    display: flex;
    align-items: center;
    width: 10%;
    max-width: 150px;
    min-width: 90px;
    justify-content: center;
    color: black;
    border-radius: 10px;
    background-color: #00FF58;
    cursor: pointer;
    transition-duration: .4s;
    border: none;
}

.buttonInfoContainer:hover{
    scale: 1.05;
}

.arrowButton{
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: gray;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 1rem;
    cursor: pointer;
    height: 1rem;
    width: 60px;
    transition-duration: .4s;
}

.arrowButton:hover{
    scale: 1.05;
}

.arrowButton:disabled{
    opacity: .6;
    cursor: not-allowed;
}

.HomeInfoContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex: 1;
    width: 80%;
}

.buttonsHomeNav{
    display: flex;
    width: 15%;
    max-width: 200px;
    min-width: 130px;
    justify-content: space-between;
}

.DateBarInput{
    display: flex;
    background-color: transparent;
    width: 80%;
    color: white;
    outline: none;
    border: none;
}