.LoginContainer{
    display: flex;
    flex-direction: column;
    
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.loginButton{
    display: flex;
    width: 100%;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    
    height: 3rem;
    border-radius: 5px;
    background-color: #00dd4d;
    border: 0;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    color: var(--secondary-text);
    transition-duration: .4s;
}

.loginButton:hover{
    background-color: var(--detail);
}

.login-field {
    width: 70%;
    max-width: 500px;
    min-width: 300px;
}

#LoginImageContainer{
    height: 8vw;
    min-height: 60px;
    max-height: 100px;
}