
.DatePickerContainer{
    display: flex;
    flex-direction: row;
    background-color: transparent;

    align-items: center;
    justify-content: space-between;
    align-self: flex-start;
    justify-self: flex-start;

    padding-left: 1rem;
    padding-right: 1.5rem;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 7px;

    width: 100%;
    height: 3.2rem;
}


.DatePickerStyle{
    background-color: transparent;
    width: 100%;
    height: 5vh;
    font-size: 0.9rem;
    color: white;
    border: none;
    outline: none;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
    width: 100%; /* Garante que o input ocupe todo o espaço disponível */
}

.react-datepicker-popper {
    width: fit-content; /* Faz o calendário popup ocupar a largura do container */
    /* ajustes adicionais para alinhar o popup conforme necessário */
    background-color: red;
}