.dropDownContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    border-radius: .5rem;
    cursor: pointer;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 10px;
    /* border: 1px solid red; */
}

.dropDownText {
    font-size: calc(5px + .5vw);
    align-self: center;
}

.dropdown-content {
    display: flex;
    position: absolute;
    width: 99.5%;
    top: 100%;
    right: 0%;
    flex-direction: column;
    background-color: #535353;
    z-index: 1;
    border-bottom-left-radius: .5rem; 
    border-bottom-right-radius: .5rem;
}

.downArrow{
    font-size: 15px;
}

.dropdown-item {
    color: black;
    padding-top: 12px;
    padding-bottom: 12px;
    text-decoration: none;
    display: block;
}