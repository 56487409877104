

.DownloadListContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    height: 80vh;
}

.DownloadListContainer .itemContainer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid white;
    padding-bottom: 1.2rem;
    margin-bottom: 1.2rem;
}

.DownloadListContainer .iconItem{
    display: flex;
    font-size: 2rem;
    color: white;
}

.DownloadListContainer .downloadItem{
    display: flex;
    font-size: 1.5rem;
    color: gray;
    cursor: pointer;
}

.DownloadListContainer .downloadItemDisabled{
    display: flex;
    font-size: 1.5rem;
    color: gray;
    cursor: not-allowed;
    opacity: .7;
}

.DownloadListContainer .itemText{
    display: flex;
    font-size: 1.3rem;
    color: white;
}