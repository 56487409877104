#contact-us-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0;
    /* border: 1px solid red; */
}

#contact-us-info > span:first-of-type {
    color: var(--detail);
    font-weight: 700;
    font-size: 1.2rem;
}

#contact-us-info > span:nth-of-type(2) {
    margin: 20px 0;
    text-align: center;
    font-size: .8rem;
}

#contact-us-info > div {
    /* border: 1px solid red; */
    width: 200px;
}

#contact-us-info > div > div {
    display: flex;
    align-items: center;
    width: 100%;
    /* border: 1px solid orange; */
}

#contact-us-info > div > div > span {
    display: inline-block;
    text-align: center;
    width: 100%;
    font-size: .8rem;
    /* border: 1px solid blue; */
}

#contact-us-info > div > div:not(:last-of-type) {
    margin-bottom: 10px;
}

#contact-us-form > p {
    font-size: 1.2rem;
    font-weight: 700;
}

#contact-us-form {
    width: 100%;
}

form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 80px;
    /* border: 1px solid red; */
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: .75rem;
}

.form-group input,
.form-group select,
.form-group textarea {
    padding: 10px;
    border: 1px solid var(--terciary-text);
    border-radius: 5px;
    font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus {
    outline: 1px solid var(--detail);
    border-color: var(--detail);
}

.full-width {
    grid-column: span 2;
}

form > div:last-of-type {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

@media (max-width: 600px) {
    form {
        display: flex;
        flex-direction: column;
    }
}