.modal-sync-button {
    color: white;
    cursor: pointer;
    transition: color .4s;
}

.modal-sync-button:hover {
    color: var(--detail);
}

#software-modal-header-label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#software-modal-header-label > *:not(:last-child) {
    margin-right: 15px;
}